import "./App.css";
import logo from "./images/logo.svg";
import calmBeach from "./images/calm.gif";

function App() {
  return (
    <div
      class="w3-display-container w3-animate-opacity w3-text-white"
      style={{
        backgroundImage: `url(${calmBeach})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundAttachment: "fixed",
      }}
    >
      <h1 className="see-you-soon">see you soon</h1>
      <div class="w3-display-topleft w3-padding-large w3-xlarge">
        <img src={logo} alt="Logo Vanessa Vargas" style={{opacity: 0.5}} />
      </div>
    </div>
  );
}

export default App;
